import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import {env} from '$env/dynamic/public'
const {PUBLIC_ENVIRONMENT} = env
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  environment:PUBLIC_ENVIRONMENT || 'production',
  dsn: 'https://4ac43276ff8740ea09f624227d45326c@o4507898679590912.ingest.de.sentry.io/4507898682474576',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
